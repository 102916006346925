import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';
import logo_name from '../assets/images/logo-name-mapper.png';
import logo_mobile from '../assets/images/logo-mobile.png';
import logo_name_mobile from '../assets/images/logo-name-mapper.png';
import { TAB_SEARCH } from '../interfaces/enums';
import { ReactComponent as Login } from '../assets/icons/login-icon.svg';
import { MainPageContext } from '../contexts/MainPageContext';
import PdfInfo from '../../src/assets/pdf_information.pdf';
import PdfInfoAdmin from '../../src/assets/pdf_information_admin.pdf';

import { Checkbox } from 'antd';
import { UserContext } from '../contexts/UserContext';
import { ReactComponent as HamburgerMenu } from '../assets/icons/hamburger-menu.svg';
interface IHeader {
  setTabIndex: React.Dispatch<React.SetStateAction<TAB_SEARCH>>;
  showModal: () => void;
  isEmp?: boolean;
  tabIndex: TAB_SEARCH;
  setHiddenMainSearch: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeaderComponent = ({
  tabIndex,
  setTabIndex,
  isEmp = false,
  showModal,
  setHiddenMainSearch,
}: IHeader) => {
  const { isMobile } = useContext(MainPageContext);
  const { userInformation, handleLoginGuest, keycloakInstance } = useContext(UserContext);
  const [isOpenMenuDropdown, setIsOpenMenuDropdown] = useState(false);
  const { openCompareMap, setOpenCompareMap } = useContext(MainPageContext);
  // const items: any = isEmp
  //   ? [
  //       {
  //         key: '1',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               setTabIndex(TAB_SEARCH.SEARCH);
  //               setHiddenMainSearch(false);
  //             }}
  //           >
  //             ค้นหาข้อมูล
  //           </div>
  //         ),
  //       },
  //       {
  //         key: '2',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               setTabIndex(TAB_SEARCH.IMPORT_LAYER_DATA);
  //               setHiddenMainSearch(false);
  //             }}
  //           >
  //             นำเข้าชั้นข้อมูล
  //           </div>
  //         ),
  //       },
  //       {
  //         key: '3',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               setTabIndex(TAB_SEARCH.EXPORT_LAYER_DATA);
  //               setHiddenMainSearch(false);
  //             }}
  //           >
  //             ส่งออกชั้นข้อมูล
  //           </div>
  //         ),
  //       },
  //       {
  //         key: '4',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               setTabIndex(TAB_SEARCH.EXPORT_REPORT);
  //               setHiddenMainSearch(false);
  //             }}
  //           >
  //             ส่งออกรายงาน
  //           </div>
  //         ),
  //       },
  //       {
  //         key: '5',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               isEmp ? navigate('/') : showModal();
  //             }}
  //           >
  //             {isEmp ? 'ออกจากระบบ' : 'เข้าสู่ระบบสำหรับเจ้าหน้าที่'}
  //           </div>
  //         ),
  //       },
  //     ]
  //   : [
  //       {
  //         key: '1',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               setTabIndex(TAB_SEARCH.SEARCH);
  //               setHiddenMainSearch(false);
  //             }}
  //           >
  //             ค้นหาข้อมูล
  //           </div>
  //         ),
  //       },
  //       {
  //         key: '2',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               setTabIndex(TAB_SEARCH.LAYER);
  //               setHiddenMainSearch(false);
  //             }}
  //           >
  //             รายการชั้นข้อมูล
  //           </div>
  //         ),
  //       },
  //       {
  //         key: '3',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               window.open(PdfInfo);
  //             }}
  //           >
  //             คู่มือการใช้งาน
  //           </div>
  //         ),
  //       },
  //       {
  //         key: '4',
  //         label: (
  //           <div
  //             style={{ fontSize: '40px' }}
  //             onClick={() => {
  //               isEmp ? navigate('/') : showModal();
  //             }}
  //           >
  //             {isEmp ? 'ออกจากระบบ' : 'เข้าสู่ระบบสำหรับเจ้าหน้าที่'}
  //           </div>
  //         ),
  //       },
  //     ];
  const items = isEmp
    ? [
        {
          label: 'ค้นหาข้อมูล',
          tabIndex: TAB_SEARCH.SEARCH,
          onclick: () => {
            setTabIndex(TAB_SEARCH.SEARCH);
            setHiddenMainSearch(false);
          },
        },
        {
          label: 'รายการชั้นข้อมูล',
          tabIndex: TAB_SEARCH.LAYER,
          onclick: () => {
            setTabIndex(TAB_SEARCH.LAYER);
            setHiddenMainSearch(false);
          },
        },
        {
          label: 'นำเข้าชั้นข้อมูล',
          tabIndex: TAB_SEARCH.IMPORT_LAYER_DATA,
          onclick: () => {
            setTabIndex(TAB_SEARCH.IMPORT_LAYER_DATA);
            setHiddenMainSearch(false);
          },
        },
        {
          label: 'ส่งออกชั้นข้อมูล',
          tabIndex: TAB_SEARCH.EXPORT_LAYER_DATA,
          onclick: () => {
            setTabIndex(TAB_SEARCH.EXPORT_LAYER_DATA);
            setHiddenMainSearch(false);
          },
        },
        {
          label: 'ส่งออกรายงาน',
          tabIndex: TAB_SEARCH.EXPORT_REPORT,
          onclick: () => {
            setTabIndex(TAB_SEARCH.EXPORT_REPORT);
            setHiddenMainSearch(false);
          },
        },
        // {
        //   label: 'คู่มือการใช้งาน',
        //   tabIndex: TAB_SEARCH.EXPORT_LAYER_DATA,
        //   onclick: () => {
        //     window.open(PdfInfoAdmin);
        //     // setTabIndex(TAB_SEARCH.EXPORT_LAYER_DATA);
        //     setHiddenMainSearch(false);
        //   },
        // },
      ]
    : [
        {
          label: 'ค้นหาข้อมูล',
          tabIndex: TAB_SEARCH.SEARCH,
          onclick: () => {
            setTabIndex(TAB_SEARCH.SEARCH);
            setHiddenMainSearch(false);
          },
        },
        {
          label: 'รายการชั้นข้อมูล',
          tabIndex: TAB_SEARCH.LAYER,
          onclick: () => {
            setTabIndex(TAB_SEARCH.LAYER);
            setHiddenMainSearch(false);
          },
        },
        // {
        //   label: 'คู่มือการใช้งาน',
        //   tabIndex: TAB_SEARCH.EXPORT_LAYER_DATA,
        //   onclick: () => {
        //     window.open(PdfInfo);
        //     // setTabIndex(TAB_SEARCH.EXPORT_LAYER_DATA);
        //     setHiddenMainSearch(false);
        //   },
        // },
      ];
  return (
    <SContainer className={`${isMobile ? 'mobile' : ''}`}>
      <div className="logo">
        {/* {isMobile ? <img alt="กรมป่าไม้" src={logo_mobile} /> : <img alt="กรมป่าไม้" src={logo} />} */}
        {/* <SLine /> */}
        {isMobile ? (
          <img alt="กรมป่าไม้" src={logo_name_mobile} />
        ) : (
          <img alt="ชื่อกรมป่าไม้" src={logo_name} />
        )}
      </div>

      {isMobile ? (
        <div className="menu">
          <div
            onClick={() => {
              setIsOpenMenuDropdown(!isOpenMenuDropdown);
            }}
          >
            <HamburgerMenu />
          </div>
          {isOpenMenuDropdown && (
            <div className={`${isEmp ? 'emp' : ''}`}>
              {items.map((item: any, i: number) => {
                return (
                  <div
                    key={i}
                    className={`${tabIndex === item.tabIndex ? 'selected' : ''} menuItem`}
                    onClick={() => {
                      item.onclick();
                      setIsOpenMenuDropdown(false);
                    }}
                  >
                    {item.label}
                  </div>
                );
              })}
              <div
                className="menuItem login"
                onClick={async () => {
                  if (userInformation?.data?.role !== 0) {
                    await keycloakInstance.logout();
                    handleLoginGuest();
                  } else {
                    showModal();
                  }
                  setIsOpenMenuDropdown(false);
                }}
              >
                <div>
                  {userInformation?.data?.role !== 0
                    ? 'ออกจากระบบ'
                    : 'เข้าสู่ระบบสำหรับเจ้าหน้าที่'}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="menu">
          {isEmp && (
            <div className={`menuItem`}>
              <Checkbox
                value={openCompareMap}
                onChange={() => {
                  setOpenCompareMap(!openCompareMap);
                }}
              >
                Compare
              </Checkbox>
            </div>
          )}
          {items.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className={`${tabIndex === item.tabIndex ? 'selected' : ''} menuItem`}
                onClick={() => {
                  item.onclick();
                }}
              >
                {item.label}
              </div>
            );
          })}

          <div
            className="menuItem login"
            onClick={() => {
              if (userInformation?.data?.role !== 0) {
                handleLoginGuest();
              } else {
                showModal();
              }
            }}
          >
            <div>
              {userInformation?.data?.role !== 0 ? 'ออกจากระบบ' : 'เข้าสู่ระบบสำหรับเจ้าหน้าที่'}
            </div>

            <Login />
          </div>
        </div>
      )}
    </SContainer>
  );
};
export default HeaderComponent;
const SContainer = styled.div`
  display: grid;
  grid-template: 1fr/30% 1fr;
  width: 100%;
  height: 90%;
  &.mobile {
    background-color: #363;
    grid-template: 1fr/20% 1fr;
    & > div.menu {
      display: flex;
      position: relative;
      justify-content: flex-end;
      & > div:nth-child(1) {
        width: fit-content;
      }
      & > div:nth-child(2) {
        border-radius: 10px;
        position: absolute;
        background-color: white;
        width: fit-content;
        &.emp {
          margin-top: 45vh;
        }
        margin-top: 25vh;
        margin-right: 10px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        width: 80%;
        & > div {
          padding: 10px 0px;
          border-bottom: 1px solid black;
          width: 100%;
        }

        & > div:last-child {
          border: none;
        }
      }
    }
  }
  & > div {
    display: flex;
    align-items: center;
    &.logo {
      & > img {
        margin-right: 20px;
      }
    }
    &.menu {
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-right: 1px solid black;
        white-space: nowrap;
        cursor: pointer;
        padding: 0px 10px;
        color: #7e7e7e;
        &.selected {
          color: black;
        }
        &.login {
          color: black;
        }
      }
    }
  }
`;
const SLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: #669966;
  margin: 0px 5px;
`;
